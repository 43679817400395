/* UNIVERSAL RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* VARIABLES */
:root {
  /* COLORS */
  --PRIMARYCOLOR: black;
  --BACKGROUNDCOLOR: white;
  --BUTTONBACKGROUNDCOLOR: #ff4d30;
  /* FONYFAMILY */
  --FONTFAMILY: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  /* FONT SIZE */
  --FONTSIZE: 1.2rem;
}

body {
  min-width: 100%;
  min-height: 100vh;
  font-family: var(--FONTFAMILY);
  padding: 1.3rem;
  font-size: var(--FONTSIZE);
  overflow-x: hidden;
  scroll-behavior: smooth;
}

#root {
  width: 100%;
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
}

button {
  cursor: pointer;
  font-family: inherit;
}

.innerDivHeader {
  background-color: var(--BUTTONBACKGROUNDCOLOR);
}
.flexInnerDiv {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

/* ::-webkit-scrollbar {
  width: 15px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 8px;
}


::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 8px;
}


::-webkit-scrollbar-thumb:hover {
  background: #b30000;
} */
