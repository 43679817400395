.teamContainerList {
  list-style-type: none;
  display: flex;
  justify-content: space-around;
  gap: 3rem;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.teamMember {
  width: 350px;
  min-height: 500px;
  box-shadow: 0px 5px 5px var(--PRIMARYCOLOR);
}

.memberImageContainer {
  width: 100%;
}

.memberImageContainer img {
  width: 100%;
  height: 400px;
}

.teamContainerList h2,
.teamContainerList p {
  text-align: center;
}
