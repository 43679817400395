.faqDiv {
  display: grid;
  place-items: center;
  position: relative;
  margin-bottom: 5rem;
}

.faqDiv .img {
  opacity: 0.4;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.faqDiv h2,
h3 {
  margin-bottom: 1rem;
  color: var(--PRIMARYCOLOR);
}

.faqDiv h4 {
  font-size: 2.5rem;
  text-align: center;
}
.questionsContainer {
  max-width: 800px;
  box-shadow: 0px 5px 5px var(--PRIMARYCOLOR);
  padding: 1.5rem;
  list-style-position: inside;
  list-style-type: upper-roman;
}

.questionItemFlex {
  display: flex;
  justify-content: space-between;
}

.questionItemFlex button {
  height: 30px;
  background-color: transparent;
  border: none;
  outline: none;
  transform: translateY(-0.1rem);
}

.questionItem {
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(128, 128, 128, 0.514);
  display: grid;
  width: 100%;
}

.hidden {
  display: none;
}

@media screen and (max-width: 430px) {
  .faqDiv .img img {
    width: 200px;
  }
}
