.nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
}

.logoImg {
  width: 145px;
  height: 50px;
}

.logoImg img {
  width: 100%;
  object-fit: cover;
}
.holdLinksRegister {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.links {
  list-style-type: none;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.links a {
  text-decoration: none;
  color: var(--PRIMARYCOLOR);
  font-weight: bolder;
  font-size: 1rem;
}

.links a:hover {
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
}

@-webkit-keyframes blink {
  100% {
    box-shadow: 0 0 3px #fff, 0 0 5px #fff, 0 0 5px #fff, 0 0 5px #ff4d30,
      0 0 5px #ff4d30, 0 0 5px #ff4d30;
  }
}

@keyframes blink {
  100% {
    box-shadow: 0 0 3px #fff, 0 0 10px #fff, 0 0 5px #fff, 0 0 5px #ff4d30,
      0 0 5px #ff4d30, 0 0 5px #ff4d30;
  }
}

.signLinks {
  display: flex;
  margin-right: 1rem;
  gap: 0.8rem;
}

.signLinks div {
  display: grid;
  align-items: center;
}

.signLinks a {
  text-decoration: none;
  color: var(--PRIMARYCOLOR);
  font-weight: 500;
  background-color: var(--BUTTONBACKGROUNDCOLOR);
  padding: 0.6rem;
  border-radius: 10px;
  font-size: 1rem;
  transition: 0.3s ease-in-out;
}

.signLinks a:hover {
  background-color: green;
}

.hamburger {
  display: none;
}

@media screen and (max-width: 998px) {
  .nav {
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .hamburger {
    display: block;
    width: 35px;
    appearance: none;
    background: none;
    border: none;
    position: relative;
    z-index: 3;
  }

  .hamburger .bar,
  .hamburger::before,
  .hamburger::after {
    content: "";
    width: 100%;
    height: 5px;
    background-color: var(--BUTTONBACKGROUNDCOLOR);
    margin: 6px 6px;
    transition: 0.4s;
    display: block;
  }

  .hamburger.is-active::before {
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  .hamburger.is-active .bar {
    opacity: 0;
  }
  .hamburger.is-active::after {
    transform: rotate(45deg) translate(-9px, -6px);
  }

  .holdLinksRegister {
    display: block;
    position: fixed;
    top: 0;
    left: 100%;
    min-height: 100vh;
    padding-top: 120px;
    width: 100%;
    background-color: var(--PRIMARYCOLOR);
    padding-top: 120px;
    line-height: 3rem;
    transition: 0.4s ease-in-out;
    overflow-x: hidden;
    text-align: center;
    z-index: 2;
  }

  .links {
    display: grid;
  }

  .signLinks {
    display: grid;
    place-content: center;
  }
  .signLinks a {
    width: 160px;
  }

  .register_Btn {
    margin-bottom: 3rem;
  }

  .holdLinksRegister.is-active {
    left: 0;
  }

  .links li {
    margin-bottom: 1rem;
    width: 100%;
  }

  .links a {
    color: var(--BACKGROUNDCOLOR);
    transition: color 0.3s ease-in-out;
  }

  .links a:hover {
    color: var(--BUTTONBACKGROUNDCOLOR);
  }
}
