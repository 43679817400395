.aboutHeader {
  position: relative;
  padding: 0.3rem;
  height: 300px;
  margin-bottom: 1rem;
}

.aboutHeader h2 {
  position: relative;
}

.aboutHeader::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url("../../ImageAssests/about/heroes-bg.png");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

.aboutPageBodyImgContainer {
  position: relative;
}

.aboutPageBody {
  position: relative;
}

.aboutPageBodyContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 5rem;
}

.aboutPageBodyImgContainer {
  flex: 1;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.aboutPageBodyImgContainer img {
  width: 100%;
  height: 100%;
}

.aboutPageBodyDescription {
  flex: 1.5;
}

.aboutPageBodyDescription h4 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.aboutPageBodyIconContainer {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.aboutPageBook {
  background-color: var(--PRIMARYCOLOR);
  color: white;
  padding: 1rem;
  margin-bottom: 5rem;
  background-image: url(../../ImageAssests/faq/bg-1.png);
  background-position: top;
}

.aboutPageBook h3 {
  color: white;
  font-size: 2.5rem;
}

.aboutPageBook span {
  color: var(--BUTTONBACKGROUNDCOLOR);
  margin-inline: 2rem;
}

@media screen and (max-width: 950px) {
  .aboutPageBodyContainer {
    display: grid;
  }

  .aboutPageBodyContainer h3,
  h4 {
    text-align: center;
  }
}
